import {setLocale} from "yup";

setLocale({
    mixed: {
        default: 'Zadali ste nesprávnu hodnotu.',
        notType: 'Zadali ste nesprávnu hodnotu.',
        required: 'Toto pole je povinné.',
    },
    string: {
        email: 'Zadaný e-mail má nesprávny formát.',
        min: 'Musíte zadať aspoň ${min} znakov.',
        max: 'Môžete zadať najviac ${max} znakov.',
    },
    number: {
        min: 'Minimálna hodnota musí byť ${min}.',
        max: 'Maximálna hodnota musí byť ${max}.',
        integer: 'Zvolená hodnota musí byť celé číslo.',
    },
});
