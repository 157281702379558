import {RouteRecordRaw} from "vue-router";

const appRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/games'
    },
    {
        path: '/',
        component: () => import("@/layouts/AppLayout.vue"),
        children: [
            // Games
            {
                path: 'games',
                component: () => import("@/pages/App/Games/GamesPage.vue")
            },
            {
                path: 'game/join/:hashtag?',
                component: () => import("@/pages/App/Games/JoinGamePage.vue")
            },

            // Shop
            {
                path: 'shop',
                component: () => import("@/pages/App/Shop/ShopPage.vue")
            },

            // Account
            {
                path: 'account',
                component: () => import("@/pages/App/Account/AccountPage.vue")
            },
        ]
    },

    // Game
    {
        path: '/game/',
        component: () => import("@/layouts/GameLayout.vue"),
        children: [
            {
                path: ':id/wait',
                component: () => import("@/pages/App/Games/Game/WaitForStartPage.vue")
            },
            {
                path: 'wait/category',
                component: () => import("@/pages/App/Games/Game/WaitSelectCategory.vue")
            },
            {
                path: 'select/category',
                component: () => import("@/pages/App/Games/Game/SelectCategory.vue")
            },
            {
                path: 'wait/question',
                component: () => import("@/pages/App/Games/Game/WaitSelectQuestion.vue")
            },
            {
                path: 'select/question',
                component: () => import("@/pages/App/Games/Game/SelectQuestion.vue")
            },
            {
                path: 'ask/question',
                component: () => import("@/pages/App/Games/Game/AskQuestion.vue")
            },
            {
                path: 'wait/ask/question',
                component: () => import("@/pages/App/Games/Game/WaitAskQuestion.vue")
            },
            {
                path: 'tip/players',
                component: () => import("@/pages/App/Games/Game/TipPlayers.vue")
            },
            {
                path: 'wait/tip/players',
                component: () => import("@/pages/App/Games/Game/WaitTipPlayers.vue")
            },
            {
                path: 'show/results',
                component: () => import("@/pages/App/Games/Game/ShowResults.vue")
            },
        ]
    },
];

export default appRoutes;
