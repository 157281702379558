import { createRouter, createWebHistory } from '@ionic/vue-router';
import routes from "@/router/routes";
import {userStore} from "@/stores/user";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const authPages = [
    "/auth/index",
    "/auth/login",
    "/auth/register",
    "/auth/terms",
    "/auth/newsletter",
  ];

  const publicPages = [
    ...authPages
  ];

  const requireLogin = !publicPages.includes(to.path);
  const requireLoggedOut = authPages.includes(to.path);

  // Nacitame z pinia storu uzivatela
  let { user } = userStore();

  // Ak tam nie je uzivatel, nacitame data z ionic/storage a nastavime ho odtial
  if (!user) {
    await userStore().loadFromStorage();

    user = userStore().user;
  }

  // Ak nie je prihlaseny a ide na stranky, ktore nema vidiet
  if (requireLogin && !user) {
    await userStore().setTo(to.path);
    next('/auth/index');
    return;
  }

  // Ak je prihlaseny a ide na stranky, ktore nema vidiet
  if (requireLoggedOut && user) {
    next('/');
    return;
  }

  next();
});

export default router
