
// eslint-disable-next-line
import { app } from './FirebaseService';
import { getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);

let conf = {
    API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
    WS_BASE_URL: process.env.VUE_APP_WS_BASE_URL,
}

if (process.env.NODE_ENV === "PROD") {
    conf = {
        API_BASE_URL: getValue(remoteConfig, "API_BASE_URL").asString(),
        WS_BASE_URL: getValue(remoteConfig, "WS_BASE_URL").asString()
    }
}

export default conf
