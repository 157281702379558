import api, {apiAuth} from "@/services/AxiosService";
import ApiService from "@/services/ApiService";
import {FormActions} from "vee-validate";

class AuthService
{
    INIT_STATE = {
        user: null,
        token: null,
        game: null,
        player: null,
        to: null,
    };

    async register(name: string,
        email: string,
        password: string,
        terms: boolean,
        newsletter: boolean,
        actions: FormActions<any>
    ) {
        await ApiService.post(api, '/auth/register', {
            name: name,
            email: email,
            password: password,
            terms: terms,
            newsletter: newsletter,
        }, actions);
    }

    async loginEmailPass(email: string, password: string, actions: FormActions<any>) {
        return await ApiService.post(api, '/auth/login', {
            email: email,
            password: password,
        }, actions);
    }

    async logOut() {
        return await ApiService.get(apiAuth, '/auth/logout');
    }
}

export default new AuthService();
