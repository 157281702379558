import {RouteRecordRaw} from "vue-router";
import authRoutes from "@/router/routes/auth";
import appRoutes from "@/router/routes/app";

const routes: Array<RouteRecordRaw> = [
    ...authRoutes,
    ...appRoutes,
];

export default routes;
