import {FormActions} from "vee-validate";
import {AxiosInstance} from "axios";

class ApiService
{
    async post(api: AxiosInstance, url: string, data: object, actions: null | FormActions<any>) {
        try {
            return await api.post(url, data);
        } catch (error: any) {
            if (actions !== null) {
                if (error?.response?.data?.errors) {
                    actions.setErrors(error.response.data.errors);
                    throw 'Vo formulári máte nesprávne zadané hodnoty.';
                }
                if (error.response?.data?.error) {
                    actions.setErrors(error.response.data.error);
                    throw error.response.data.error;
                }
            }

            throw 'Nastala neočakávaná chyba. Opakujte akciu neskôr prosím.';
        }
    }

    async get(api: AxiosInstance, url: string) {
        try {
            return await api.get(url);
        } catch (error: any) {
            if (error.response?.data?.error) {
                throw error.response.data.error;
            }

            throw 'Nastala neočakávaná chyba. Opakujte akciu neskôr prosím.';
        }
    }
}

export default new ApiService();
