import axios from "axios";
import {userStore} from "@/stores/user";
import router from "@/router";
import errorAlert from "@/services/UI/ErrorAlert";
import configService from "@/services/ConfigService";

// const BASE_URL = "http://localhost/api";
const BASE_URL = configService.API_BASE_URL + "/api";

const authenticatedApi = axios.create({
    baseURL: BASE_URL,
    // headers: {
    //     "X-Socket-Id": EchoService.echo?.socketId(),
    // },
});

authenticatedApi.interceptors.request.use(function (config) {
    // Nastavime authorizacny token
    const token = userStore().token;
    config.headers.set('Authorization', 'Bearer ' + token);

    return config;
}, function (error) {
    return Promise.reject(error);
});

authenticatedApi.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (!error.response) {
        // await errorAlert('Upozornenie', 'Nastala chyba pri komunikáci zo serverom. Uistite sa, že máte internetové pripojenie. Opakujte akciu alebo kontaktujte administrátora.');

        return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
        await userStore().resetUser();
        await router.replace("/auth/index");

        return Promise.reject(error);
    }

    if (error.response) {
        if (error.response?.data?.error || error?.response?.data?.errors) {
            return Promise.reject(error);
        }

        // const msg = 'Nastala neznáma chyba pri komunikácii so serverom. Opakujte akciu alebo kontaktujte administrátora.';
        //
        // await errorAlert('Upozornenie', msg);

        return Promise.reject(error);
    }

    return Promise.reject(error);
});

export const apiAuth = authenticatedApi;

const api = axios.create({ baseURL: BASE_URL });

api.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (!error.response) {
        // await errorAlert('Upozornenie1', 'Nastala chyba pri komunikáci zo serverom. Uistite sa, že máte internetové pripojenie. Opakujte akciu alebo kontaktujte administrátora.');

        return Promise.reject(error);
    }

    if (error.response) {
        if (error.response?.data?.error) {
            return Promise.reject(error);
        }

        // const msg = 'Nastala neznáma chyba pri komunikácii so serverom. Opakujte akciu alebo kontaktujte administrátora.';

        // await errorAlert('Upozornenie2', msg);

        return Promise.reject(error);
    }

    return Promise.reject(error);
});

export default api;
