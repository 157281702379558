import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import {createPinia} from "pinia";
import '@/services/YupSettings';
import {createStorage} from "@/stores/storage";
import './registerServiceWorker';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';

// eslint-disable-next-line
import { app, analytics, perf } from './services/FirebaseService';
// eslint-disable-next-line
import configService from "@/services/ConfigService";

const startApp = async () => {
    await createStorage;
    const pinia = createPinia();

    const app = createApp(App)
        .use(IonicVue, {
            scrollAssist: false,
        })
        .use(pinia)
        .use(router);

    router.isReady().then(async () => {
        app.mount('#app');
    });
}

startApp().then();
