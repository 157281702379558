// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Storage} from '@ionic/storage'

class AppStorageData
{
    public user: null;
    public token: null;
    public player: null;
    public game: null;
    public to: null;

    constructor() {
        this.user = null;
        this.token = null;
        this.player = null;
        this.game = null;
        this.to = null;
    }
}

class AppStorage
{
    private readonly STORAGE_KEY: string = 'app.storage';
    private storage: Storage;

    constructor() {
        this.storage = new Storage()
    }

    async init() {
        await this.storage.create();
    }

    async setData(AppStorageData: AppStorageData) {
        await this.storage.set(this.STORAGE_KEY, JSON.stringify(AppStorageData))
    }

    async getData() {
        const storageData = await this.storage.get(this.STORAGE_KEY);

        if (!storageData) {
            const appStorageData = new AppStorageData();
            await this.setData(appStorageData);

            return appStorageData;
        }

        return JSON.parse(storageData);
    }
}

const newAppStorage = new AppStorage();

const createStorageInit = async () => {
    const storage = newAppStorage;
    await storage.init();
    // await storage.getData();

    return storage;
};

export const createStorage = createStorageInit();
export const appStorage = newAppStorage;

// const storage = {
//     install: async (app: any) => {
//         const appStorage = new AppStorage();
//         await appStorage.init();
//         await appStorage.getData();
//
//         app.config.globalProperties.$storage = appStorage;
//         app.config.globalProperties.$router.$storage = appStorage;
//     }
// }
//
// export default storage;
