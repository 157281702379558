import {defineStore} from "pinia";
import AuthService from "@/services/AuthService";
import {FormActions} from "vee-validate";
import {appStorage} from "@/stores/storage";

export const userStore = defineStore('user', {
    state() {
        return {
            ...AuthService.INIT_STATE,
        };
    },
    actions: {
        async loadFromStorage() {
            const storage = await appStorage.getData();

            userStore().user = storage.user;
            userStore().token = storage.token;
            userStore().player = storage.player;
            userStore().game = storage.game;
        },
        async resetUser() {
            userStore().user = null;
            userStore().token = null;
            userStore().player = null;
            userStore().game = null;
            userStore().to = null;

            const storageData = await appStorage.getData();
            storageData.user = null;
            storageData.token = null;
            storageData.player = null;
            storageData.game = null;
            storageData.to = null;

            await appStorage.setData(storageData);
        },
        async loginEmailPass(email: string, password: string, actions: FormActions<any>) {
            const response = await AuthService.loginEmailPass(email, password, actions);

            this.user = response.data.user;
            this.token = response.data.access_token;

            const storageData = await appStorage.getData();
            storageData.user = this.user;
            storageData.token = this.token;

            await appStorage.setData(storageData);
        },
        async logOut() {
            await AuthService.logOut();
            await this.resetUser();
        },
        async setPlayer(player: any) {
            this.player = player;

            const storageData = await appStorage.getData();
            storageData.player = this.player;

            await appStorage.setData(storageData);
        },
        async setGame(game: any) {
            this.game = game;

            const storageData = await appStorage.getData();
            storageData.game = this.game;

            await appStorage.setData(storageData);
        },
        async setTo(to: any) {
            this.to = to;

            const storageData = await appStorage.getData();
            storageData.to = this.to;

            await appStorage.setData(storageData);
        }
    }
});
