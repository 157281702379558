// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA39x1op-UDKGh3a1l796B6Ot980QvkYpA",
    authDomain: "natipuj-1.firebaseapp.com",
    projectId: "natipuj-1",
    storageBucket: "natipuj-1.appspot.com",
    messagingSenderId: "881230845363",
    appId: "1:881230845363:web:4781bd2cadaea022cfe7c6",
    measurementId: "G-4CBZ36JBYB",
    API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
    WS_BASE_URL: process.env.VUE_APP_WS_BASE_URL,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const perf = getPerformance(app);
