import {RouteRecordRaw} from "vue-router";

const authRoutes: Array<RouteRecordRaw> = [{
    path: "/auth",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
        {
            path: 'index',
            component: () => import("@/pages/Auth/IndexPage.vue")
        },
        {
            path: "login",
            component: () => import("@/pages/Auth/LoginPage.vue"),
        },
        {
            path: "register",
            component: () => import("@/pages/Auth/RegisterPage.vue"),
        },
        {
            path: "terms",
            component: () => import("@/pages/Auth/TermsPage.vue"),
        },
        {
            path: "newsletter",
            component: () => import("@/pages/Auth/NewsletterPage.vue"),
        },
    ],
}];

export default authRoutes;
